import { Flex, Box } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { useState } from 'react'
import { useEffect } from 'react'

const ErrorPage = ({ error, componentStack, resetError }) => {
  const [cachedState, setCachedState] = useState()
  useEffect(() => {
    Sentry.captureException(error)
    const state = window.localStorage.getItem('state')
    console.log(error)
    if (state) {
      Sentry.captureMessage(state, 'fatal')
      setCachedState(state)
      window.localStorage.removeItem('state')
    }
  }, [])

  return (
    <Flex
      width={'100vw'}
      height={'100vh'}
      backgroundColor={'white'}
      padding={'1em'}
      direction={'column'}
    >
      <Box>
        Something went wrong and we apologize for the inconvenience. A bug
        report has been automatically sent to our development team and rest
        assure this would be resolved.
      </Box>
      <Box marginTop={'1em'}>
        Please refresh the page to get back to the designer
      </Box>

      <Box marginTop={'10em'}> {cachedState}</Box>
    </Flex>
  )
}

export default ErrorPage
