//@ts-nocheck
export const pageview = (url: string): void => {
  if (typeof window !== 'undefined') {
    window.gtag('config', process.env.NEXT_PUBLIC_GA_ID, {
      page_path: url,
    })
  }
}

export const event = ({
  action,
  params,
}: {
  action: string
  params: any
}): void => {
  if (typeof window !== 'undefined') {
    window.gtag('event', action, params)
  }
}
