import React, { useEffect, useState } from 'react'
import CookieConsent from 'react-cookie-consent'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { getLocalStorage, setLocalStorage } from 'lib/storageHelper'

function Consent() {
  const [cookieConsent, setCookieConsent] = useState(false)
  const router = useRouter()
  const currentPage = router.pathname

  useEffect(() => {
    const storedCookieConsent = getLocalStorage('cookie_consent', null)

    setCookieConsent(storedCookieConsent)
  }, [setCookieConsent])

  useEffect(() => {
    const newValue = cookieConsent ? 'granted' : 'denied'

    typeof window !== 'undefined' &&
      window.gtag('consent', 'update', {
        analytics_storage: newValue,
      })

    setLocalStorage('cookie_consent', cookieConsent)
  }, [cookieConsent])
  return (
    <>
      {currentPage !== '/privacy-policy' && (
        <CookieConsent
          location='bottom'
          buttonText='Accept'
          cookieName='localConsent'
          declineButtonText='Decline'
          enableDeclineButton
          onAccept={() => setCookieConsent(true)}
          onDecline={() => setCookieConsent(false)}
          style={{
            background: 'black',
            color: 'white',
            textAlign: 'left',
            padding: '10px',
          }}
          buttonStyle={{
            borderRadius: '10px',
            background: 'white',
            color: 'black',
          }}
          declineButtonStyle={{
            borderRadius: '10px',
            background: 'gray',
            color: 'white',
          }}
        >
          We use cookies to understand how customers use our services and to
          further enhance user experiance and to analyze traffic on our website.
          To learn more, please read our
          <Link href='/privacy-policy' legacyBehavior>
            <a target='_blank' style={{ textDecoration: 'underline' }}>
              {' '}
              Privacy Policy
            </a>
          </Link>
        </CookieConsent>
      )}
    </>
  )
}

export default Consent
